import * as React from 'react'
import Layout from '../../layout'
import { useAuth } from '../../hooks/useAuth'
import TermsPage from '../../pagesComponents/login/terms'
import TimeoutModal from '../../components/timeoutModal'

const Terms = () => {
	const { resetInVenueOnboarding } = useAuth()

	return (
		<Layout title="Terms and Conditions">
			<TermsPage
				basePath="/in-venue-onboarding"
				backBtn={-1}
				inVenue
				closeBtnText="Exit"
				successPath="/in-venue-onboarding/event-code"
				fullWidthActionBtns
				onClose={async () => {
					resetInVenueOnboarding()
				}}
			/>
			<TimeoutModal onReset={resetInVenueOnboarding} />
		</Layout>
	)
}

export default Terms
